import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

import imgBlock1 from "../images/about-block-1.jpg"
import imgBlock2 from "../images/about-block-2.jpg"
import imgBlock3 from "../images/about-block-3.jpg"
import imgBlock4 from "../images/about-block-4.jpg"
import imgLindseyGoodman from "../images/review-lindsey-goodman.jpg"
import imgCharleneRomano from "../images/review-charlene-romano.jpg"

import "./about.styles.scss"

const contentful = require("contentful")
const contentfulClient = contentful.createClient({
  space: process.env.GATSBY_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_CONTENTFUL_ACCESS_TOKEN,
})

const AboutPage = ({ location }) => {
  const [danBio, setDanBio] = useState(null)

  useEffect(() => {
    contentfulClient
      .getEntries({
        content_type: "link",
        "fields.id": "danparasky-bios",
      })
      .then(response => {
        setDanBio(response.items[0])
      })
      .catch(console.error)
  }, [])

  return (
    <Layout className="about" pathname={location.pathname}>
      <Seo
        title="About"
        description="Dan Parasky offers training and guidance to private music instructors as well as flute instruction at Parasky Flute Studios."
      />
      <section className="hero about__hero is-medium">
        <div className="container hero-body">
          <h1 className="title is-size-2-mobile is-size-1-tablet has-text-shadow">
            Transforming the Private Music Studio
          </h1>
        </div>
      </section>
      <section className="about__bio">
        <div className="container container--text is-size-6">
          <div className="content">
            <p className="has-border-left-primary-2 is-size-4">
              “We need to offer more than just weekly lessons” is a thought that
              changed my life while chatting with a student over 20 years ago.
            </p>
            <p>
              Most people think of teaching private lessons as a backup career
              choice but that was never the case for me. I knew that I wanted to
              be a performance major to learn everything about the flute. My
              teachers at Duquesne University were tough but they forced me to
              become the best player possible. Blood, sweat, and even some tears
              shaped me into the teacher and player that I am today.
            </p>
            <p>
              Long story short, I inherited 15 private students from a friend
              that was moving away for graduate school before I finished my
              undergrad.
            </p>
            <p>So, what does a young 21-year-old running a new business do?</p>
            <p>
              I jumped in head on to be the best private flute teacher
              imaginable. My roster of students doubled within one year and I
              have been running the Parasky Flute Studios full time since.
            </p>
            <p>It wasn’t enough though…</p>
            <p>
              I loved teaching flute lessons but knew there was more to deliver
              to my students. “We need to offer more than just weekly lessons”
              hit me like a ton of bricks when I was chatting with a student
              about their dance studio.
            </p>
            <p>
              Why aren’t we creating experiences that DRAW our students through
              the doors more than one time a week?
            </p>
            <p>
              That’s when my studio ensemble program was born, and I never
              looked back.
            </p>
            <p>
              <em>
                Teaching lessons is only the first step but creating experiences
                that your students love will keep them engaged in music for
                life.
              </em>
            </p>
            <p>
              I cannot wait for us to work together and explore how to enhance
              your private music studio offerings.
            </p>
          </div>
          <div className="bio__actions my-4">
            {danBio && (
              <a
              href={danBio.fields.file.fields.file.url}
                target="_blank"
                rel="noreferrer"
                className="button is-primary is-outlined is-flex is-align-items-center arrow-right-to-external"
              >
                Download Dan’s Professional Bios <FaArrowRight className="ml-3" />
              </a>
            )}
          </div>
        </div>
      </section>
      <section className="about__personal has-background-grey-lighter">
        <div className="container container--text">
          <div className="content is-size-6 has-border-left-primary-2">
            <h2 className="subtitle">Regular Life Stuff</h2>
            <p>
              I am married to my high school sweetheart. Jackie constantly
              pushes me to be better in everything that I do and has supported
              every step of my music career from the very beginning. I would not
              be where I am without her!
            </p>
            <p>
              Running is our weekend activity, so you’ll find us getting in our
              long run every Saturday followed by dinner and some drinks
              afterwards.
            </p>
            <p>
              Our three cats - Willow, Lily and Daisy round out our household.
            </p>
            <p>
              Video games are my second (or first) passion. Nowadays, I squeeze
              in some Destiny after teaching flute lessons. If professional
              gaming was a thing when I was younger, that might have been my
              dream job instead of running the Parasky Flute Studios.
            </p>
          </div>
        </div>
        <div className="container mt-6">
          <div className="personal__img-block columns is-mobile">
            <div className="column is-one-quarter">
              <figure className="image">
                <img
                  src={imgBlock1}
                  alt=""
                  role="presentation"
                  className="has-border-radius-2 has-box-shadow is-75-percent-mobile-xl"
                />
              </figure>
            </div>
            <div className="column is-one-quarter">
              <figure className="image">
                <img
                  src={imgBlock2}
                  alt=""
                  role="presentation"
                  className="has-border-radius-2 has-box-shadow is-75-percent-mobile-xl"
                />
              </figure>
            </div>
            <div className="column is-one-quarter">
              <figure className="image">
                <img
                  src={imgBlock3}
                  alt=""
                  role="presentation"
                  className="has-border-radius-2 has-box-shadow is-75-percent-mobile-xl"
                />
              </figure>
            </div>
            <div className="column is-one-quarter">
              <figure className="image">
                <img
                  src={imgBlock4}
                  alt=""
                  role="presentation"
                  className="has-border-radius-2 has-box-shadow is-75-percent-mobile-xl"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-6">
        <div className="container">
          <div className="content has-text-centered pt-6 pb-4">
            <h3 className="mb-2">Testimonials</h3>
          </div>
          <div className="testimonial-grid">
            <div className="testimonial-grid__item goodman">
              <div className="card">
                <div className="card-content">
                  <div className="content is-size-6">
                    <div className="styled-quote-symbol has-text-primary">
                      &ldquo;
                    </div>
                    A paragon of organization, Dan is a pleasure to work with at
                    all times, but, especially for projects like the National
                    Flute Association's High School Flute Choir adjudication or
                    as guest artist for my private studio, his attention to
                    detail and clear explanations of student outcomes shine,
                    illuminating his singular gifts as a flutist-educator of
                    high distinction.
                    <div className="styled-quote-symbol has-text-primary has-text-right">
                      &rdquo;
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-64x64 m-0">
                        <img src={imgLindseyGoodman} alt="Lindsey Goodman" />
                      </figure>
                    </div>
                    <div className="media-content is-size-6-mobile is-size-7-tablet is-size-6-desktop">
                      <p>
                        <h4 className="title is-size-5 mb-0">Lindsey Goodman</h4>
                        <a
                          href="https://www.lindseygoodman.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          lindseygoodman.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-grid__item romano">
              <div className="card">
                <div className="card-content">
                  <div className="content is-size-6">
                    <div className="styled-quote-symbol has-text-primary">
                      &ldquo;
                    </div>
                    I had the pleasure of working with Dan Parasky when I served
                    as coordinator of the Mid-Atlantic High School Flute Choir.
                    Dan was a brilliant and engaging guest conductor, who built
                    bridges with students during our weekend of rehearsals, and
                    inspired them to play at their highest level. Reviews from
                    students were overwhelmingly positive. Dan made this event
                    fun and memorable. Additionally, he was highly organized and
                    presented a broad and diverse program. Dan also created
                    video tutorials to help students and their teachers prepare
                    repertoire in advance. As coordinator, I couldn't have asked
                    for an easier guest conductor to collaborate with, and the
                    students enjoyed a weekend that will continue to inspire
                    them for the rest of their lives!
                    <div className="styled-quote-symbol has-text-primary has-text-right">
                      &rdquo;
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <figure className="image is-64x64 m-0">
                        <img src={imgCharleneRomano} alt="Charlene Romano" />
                      </figure>
                    </div>
                    <div className="media-content is-size-6-mobile is-size-7-tablet is-size-6-desktop">
                      <p>
                        <h4 className="title is-size-5 mb-0">Charlene Romano</h4>
                        <a
                          href="http://charleneromano.mymusicstaff.com/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          charleneromano.mymusicstaff.com
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonial-grid__item umble">
              <div className="card">
                <div className="card-content">
                  <div className="content is-size-6">
                    <div className="styled-quote-symbol has-text-primary">
                      &ldquo;
                    </div>
                    Dan Parasky was an inspiration for the Youngstown State
                    Flute Studio. He presented a recital of beautifully
                    performed pieces and a valuable workshop on running your own
                    flute studio - something he has much experience and success
                    doing. He included great ideas for scheduling, tuition,
                    policies, and organization.
                    <div className="styled-quote-symbol has-text-primary has-text-right">
                      &rdquo;
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left is-size-6">
                      <p className="title is-5">Dr. Umble</p>
                    </div>
                    <div className="media-content"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-6 pt-6 is-flex is-justify-content-center">
            <Link
              to="/guides"
              className="button is-primary mr-4 mb-3 arrow-right-enlarge is-size-7-mobile is-size-6-tablet"
            >
              Check out my guides to enhance your studio offerings{" "}
              <FaArrowRight className="ml-3" />
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage
